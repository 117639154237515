<template>
    <Toast/>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <h4>Kimutatások</h4>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                        <Calendar
                            inputId="calendar"
                            v-model="dateFrom"
                            dateFormat="yy. mm. dd."
                            :showIcon="true"
                            :showButtonBar="true">
                        </Calendar>
                        <label for="calendar">Időszak kezdete</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-4">
                        <span class="p-float-label">
                        <Calendar
                            inputId="calendar"
                            v-model="dateTo"
                            dateFormat="yy. mm. dd."
                            :showIcon="true"
                            :showButtonBar="true">
                        </Calendar>
                        <label for="calendar">Időszak vége</label>
                        </span>
                    </div>
                </div>
                <b>Gyermek adatai</b>
                <div class="grid p-fluid mt-3">
                    <div class="field col-12 lg:col-4 xl:col-3">
                        <span class="p-float-label">
                        <InputNumber id="ageFrom" v-model="ageFrom" showButtons mode="decimal" :min="0" :max="18"></InputNumber>
                        <label for="inputnumber">Alsó korhatár (év)</label>
                        </span>
                    </div>
                    <div class="field col-12 lg:col-4 xl:col-3">
                        <span class="p-float-label">
                        <InputNumber id="ageTo" v-model="ageTo" showButtons mode="decimal" :min="0" :max="18"></InputNumber>
                        <label for="inputnumber">Felső korhatár (év)</label>
                        </span>
                    </div>
                    <div class="field col-12 lg:col-4 xl:col-3">
                        <span class="p-float-label">
                        <Dropdown id="gender" :options="genders" v-model="genderValue" optionLabel="name"></Dropdown>
                        <label for="gender">Neme</label>
                        </span>
                    </div>
                </div>
                <b>Felhasználó adatai</b>
                <div class="grid p-fluid mt-3">
                    <div class="field col-12 lg:col-4 xl:col-3">
                        <span class="p-float-label">
                        <Dropdown id="isActive" :options="standardOptions" v-model="activeValue" optionLabel="name"></Dropdown>
                        <label for="isActive">Profil aktiválva</label>
                        </span>
                    </div>
                    <div class="field col-12 lg:col-4 xl:col-3">
                        <span class="p-float-label">
                        <Dropdown id="newsletter" :options="standardOptions" v-model="newsletterValue" optionLabel="name"></Dropdown>
                        <label for="newsletter">Szeretne hírlevelet</label>
                        </span>
                    </div>
                </div>
                <div>
                    <h5>Felhasználói adatok exportálása</h5>
                    <Button label="Felhasználók" class="mr-2 mb-2" @click="exportUsers"></Button>
                    <Button label="Korcsoportok" class="mr-2 mb-2" @click="exportAgeGroups"></Button>
                </div>
                <div>
                    <h5>Esemény adatok exportálása</h5>
                    <Listbox
                        v-model="eventValue"
                        :options="eventValues"
                        optionLabel="name"
                        :filter="true"
                        listStyle="max-height:250px"
                    />
                    <div class="mt-3">
                        <Button label="Jelentkezések" class="mr-2 mb-2" @click="exportApplicants"></Button>
                        <Button label="Statisztikák" class="mr-2 mb-2" @click="exportEventStatistics"></Button>
                    </div>
                </div>
                <div>
                    <h5>Összes esemény adatainak exportálása</h5>
                    <div class="mt-3">
                        <Button label="Résztvevők száma" class="mr-2 mb-2" @click="exportNumberOfApplicants"></Button>
                    </div>
                </div>
                <div>
                    <h5>Üzenet küldése export alapján</h5>
                    <div class="mt-3">
                        <Button label="Üzenet küldése" icon="pi pi-envelope"
                            class="mr-2 mb-2"
                            @click="openNewMessage"
                            :disabled="!recipients || !recipients.length"/>
                    </div>
                </div>

                <Dialog v-model:visible="messageDialog" :style="{width: '700px'}" header="Üzenet küldése"
                        :modal="true" class="p-fluid">
                    <div class="field">
                    <li class="flex align-items-center py-3 px-2 flex-wrap surface-50">
                        <div class="text-500 w-full md:w-4 font-medium">Címzettek({{ recipients.length }})</div>
                        <div class="text-900 w-full md:w-6">
                            {{ recipients.map((recipient) => recipient).join(", ") }}
                        </div>
                    </li>
                    </div>
                    
                    <div class="field">
                        <label for="name">Tárgy</label>
                        <InputText id="subject" v-model.trim="message.subject" required="true"
                                   :class="{'p-invalid': messageSubmitted && !message.subject}"/>
                        <small class="p-invalid" v-if="messageSubmitted && !message.subject">Tárgy megadása
                            kötelező.</small>
                    </div>

                    <div class="field">
                        <label for="message">Üzenet</label>
                        <ckeditor :editor="editor" v-model="messageText" :config="editorConfig"></ckeditor>
                        <small class="p-invalid" v-if="messageSubmitted && !messageText">Üzenet megadása
                            kötelező.</small>
                    </div>

                    <div class="field">
                        <label for="attachment">Melléklet</label>
                        <FileUpload mode="basic" name="attachment" :customUpload="true" @uploader="onUploadAttachment"
                                    :auto="true"
                                    :multiple="true"
                                    :maxFileSize="10000000" class="p-button-outlined p-button-plain"
                                    :chooseLabel="attachment"></FileUpload>
                    </div>

                    <template #footer>
                        <Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Küldés" icon="pi pi-envelope" class="p-button-primary" @click="sendMessage"
                                :loading="messageLoading"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>
import StatService from "@/service/StatService";
import MessageService from "@/service/MessageService";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { saveAs } from 'file-saver';
import * as dayjs from 'dayjs'

export default {
    data() {
        return {
            eventValues: [
                {name: '', id: 0},
            ],
            eventValue: null,
            loading: true,
            ageFrom: null,
            ageTo: null,
            dateFrom: null,
            dateTo: null,
            genderValue: null,
            genders: [
                {name: 'Mindegy', id: null},
                {name: 'Fiú', id: 0},
                {name: 'Lány', id: 1},
            ],
            activeValue: null,
            newsletterValue: null,
            standardOptions: [
                {name: 'Mindegy', id: null},
                {name: 'Igen', id: true},
                {name: 'Nem', id: false},
            ],
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', '|',
                        'bold', 'italic',
                        'link', '|',
                        'outdent', 'indent', '|',
                        'bulletedList', 'numberedList', '|',
                        'undo', 'redo'
                    ],
                    shouldNotGroupWhenFull: true
                }
            },
            attachment: '',
            recipients: [],
            messageLoading: false,
            messageDialog: false,
            showMessageDialog: false,
            message: {
                recipients: []
            },
            messageSubmitted: false,
            messageText: '',
        };
    },
    statService: null,
    messageService: null,
    created() {
        this.statService = new StatService();
        this.messageService = new MessageService();
    },
    mounted() {
        this.getEvents();
    },
    methods: {
        openNewMessage() {
            this.attachment = '';
            this.message = {};
            this.messageText = '';
            this.messageSubmitted = false;
            this.messageDialog = true;
        },
        hideDialog() {
            this.messageDialog = false;
            this.messageSubmitted = false;
        },
        onUploadAttachment(event) {
            this.attachment = '';
            delete this.message.attachment;
            const files = event.files.slice(0, 2);
            const base64Datas = [];
            const fileNames = [];
            files.forEach(file => {
                fileNames.push(this.$options.filters.truncate(file.name, 30));
                const reader = new FileReader();
                reader.onload = () => {
                    const base64Data = reader.result;
                    base64Datas.push(base64Data);
                };
                reader.readAsDataURL(file);
            });
            this.attachment = fileNames.join(", ");
            this.message.attachment = base64Datas;
        },
        getEvents() {
            this.statService.getEvents().then(response => {
                const events = response.data.data;
                this.eventValues = events.map(({id, name}) => ({id, name}));
                this.loading = false;
            });
        },
        async exportUsers() {
            this.recipients = [];
            const criteria = this.getCriteria();

            const response = await this.statService.exportUsers(criteria);
            const responseRecipients = await this.statService.getUserRecipients(criteria);
            this.recipients = responseRecipients.data;
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_felhasznalok.xlsx`;
            saveAs(response.data, filename);
        },
        async exportApplicants() {
            this.recipients = [];
            const criteria = this.getCriteria();

            const response = await this.statService.exportApplicants(criteria);
            const responseRecipients = await this.statService.getApplicantRecipients(criteria);
            this.recipients = responseRecipients.data;
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_jelentkezok.xlsx`;
            saveAs(response.data, filename);
        },
        async exportAgeGroups() {
            this.recipients = [];
            const criteria = this.getCriteria();

            const response = await this.statService.exportAgeGroups(criteria);
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_korcsoportok.xlsx`;
            saveAs(response.data, filename);
        },
        async exportEventStatistics() {
            this.recipients = [];
            const criteria = this.getCriteria();

            const response = await this.statService.exportEventStatistics(criteria);
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_esemény_statisztikák.xlsx`;
            saveAs(response.data, filename);
        },
        async exportNumberOfApplicants() {
            this.recipients = [];
            const criteria = this.getCriteria();

            const response = await this.statService.exportNumberOfApplicants(criteria);
            const today = dayjs().format('YYYY-MM-DD');
            const filename = `${today}_résztvevők_száma.xlsx`;
            saveAs(response.data, filename);
        },
        getCriteria() {
            return {
                eventName: this.eventValue && this.eventValue['name'],
                dateFrom: this.dateFrom,
                dateTo: this.dateTo ? dayjs(this.dateTo).add(1, 'day').toDate() : null,
                ageFrom: this.ageFrom,
                ageTo: this.ageTo,
                gender: this.genderValue && this.genderValue['id'],
                isActive: this.activeValue && this.activeValue['id'],
                newsletter: this.newsletterValue && this.newsletterValue['id']
            }
        },
        sendMessage() {
            this.messageSubmitted = true;

            if (
                !this.message.subject ||
                !this.messageText
            ) {
                return;
            }

            this.messageLoading = true;

            if (this.message.subject.trim()) {
                let recipients = [];

                if (this.recipients) {
                    recipients = this.recipients;
                }

                let message = {
                    recipients: recipients,
                    attachment: this.message.attachment,
                    subject: this.message.subject,
                    message: this.messageText,
                    type: 0,
                };

                this.messageService.create(message).then(() => {
                    this.messageDialog = false;

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sikeres művelet!',
                        detail: 'Az üzenet kiküldése sikeres volt.',
                        life: 5000
                    });

                    this.messageLoading = false;
                    this.messageSubmitted = false;
                    this.recipients = [];
                    this.messageText = '';
                    this.message.subject = '';
                    this.attachment = '';
                    this.message = {};
                }).catch(e => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Sikertelen művelet!",
                        detail: e.response.data.error,
                        life: 6000,
                    });

                    this.messageLoading = false;
                    this.messageSubmitted = false;
                });
            }
        },
    },
    filters: {
        truncate: function (text, length) {
            if (text.length > length) {
                return text.substring(0, length) + '...'
            } else {
                return text
            }
        },
    },
}
</script>

<style>
.ck-editor__editable {
    min-height: 300px;
}
</style>
